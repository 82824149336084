main {
  width: calc(100% - 300px);
}

html {
  background: #f5f5f5;
}

html,
body {
  min-height: 100%;
  .p-0 {
    padding: 0px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-30 {
    padding: 30px;
  }
  .p-l-0 {
    padding-left: 0px;
  }
  .p-l-3 {
    padding-left: 3px;
  }
  .p-l-5 {
    padding-left: 5px;
  }
  .p-l-8 {
    padding-left: 8px;
  }
  .p-l-10 {
    padding-left: 10px;
  }
  .p-l-15 {
    padding-left: 15px;
  }
  .p-l-20 {
    padding-left: 20px;
  }
  .p-l-30 {
    padding-left: 30px;
  }
  .p-l-40 {
    padding-left: 40px;
  }
  .p-r-0 {
    padding-right: 0px;
  }
  .p-r-3 {
    padding-right: 3px;
  }
  .p-r-5 {
    padding-right: 5px;
  }
  .p-r-8 {
    padding-right: 8px;
  }
  .p-r-10 {
    padding-right: 10px;
  }
  .p-r-15 {
    padding-right: 15px;
  }
  .p-r-20 {
    padding-right: 20px;
  }
  .p-r-30 {
    padding-right: 30px;
  }
  .p-r-40 {
    padding-right: 40px;
  }
  .p-t-0 {
    padding-top: 0px;
  }
  .p-t-3 {
    padding-top: 3px;
  }
  .p-t-5 {
    padding-top: 5px;
  }
  .p-t-8 {
    padding-top: 8px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-0 {
    padding-bottom: 0px;
  }
  .p-b-3 {
    padding-bottom: 3px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-8 {
    padding-bottom: 8px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
  .p-b-40 {
    padding-bottom: 40px;
  }
  .m-0 {
    margin: 0px;
  }
  .m-l-5 {
    margin-left: 5px;
  }
  .m-l-10 {
    margin-left: 10px;
  }
  .m-l-15 {
    margin-left: 15px;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .m-l-30 {
    margin-left: 30px;
  }
  .m-l-40 {
    margin-left: 40px;
  }
  .m-r-5 {
    margin-right: 5px;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .m-r-15 {
    margin-right: 15px;
  }
  .m-r-20 {
    margin-right: 20px;
  }
  .m-r-30 {
    margin-right: 30px;
  }
  .m-r-40 {
    margin-right: 40px;
  }
  .m-t-0 {
    margin-top: 0px;
  }
  .m-t-2 {
    margin-top: 2px;
  }
  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-t-40 {
    margin-top: 40px;
  }
  .m-b-0 {
    margin-bottom: 0px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
  .vt {
    vertical-align: top;
  }
  .vm {
    vertical-align: middle;
  }
  .vb {
    vertical-align: bottom;
  }
  .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13);
  }
  .overflow-hidden {
    overflow: hidden;
  }
}

.snackbar .MuiSnackbarContent-root {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgb(50, 50, 50);
  font-weight: 500;
}

@media (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter.snackbar {
    left: 60%;
  }
  .MuiSnackbar-anchorOriginBottomLeft.snackbar {
    left: 26%;
  }
}

.flex-space {
  flex: 1;
}

.content-wrapper {
  padding: 8px 6px 30px;
  @media screen and (min-width: 600px) {
    margin-top: 70px;
  }

  &.with-float {
    padding-bottom: 78px;
  }
  &.with-footer {
    padding-bottom: 82px;
  }
  &.with-float-amt {
    padding-bottom: 110px;
  }
  &.with-footer-n-float {
    padding-bottom: 120px;
  }
  &.with-tab {
    margin-top: 0px;
    @media screen and (min-width: 600px) {
      margin-top: 0px !important;
    }
  }
  &.with-search {
    margin-top: 34px;
    @media screen and (min-width: 600px) {
      margin-top: 110px !important;
    }
  }
  &.search-dropdown-filter {
    margin-top: 44px;
    @media screen and (min-width: 600px) {
      margin-top: 110px !important;
    }
  }
  &.triple-dynamic-search {
    margin-top: 128px;
    @media screen and (min-width: 600px) {
      margin-top: 200px !important;
    }
  }
  &.multiple-dynamicInfo {
    margin-top: 53px;
    @media screen and (min-width: 600px) {
      margin-top: 122px !important;
    }
  }
  &.single-dynamicInfo {
    margin-top: 38px;
    @media screen and (min-width: 600px) {
      margin-top: 105px !important;
    }
  }
  &.dynamicinfo-search {
    margin-top: 71px;
    @media screen and (min-width: 600px) {
      margin-top: 135px !important;
    }
  }
  &.multi-dynamicinfo-search {
    margin-top: 90px;
    @media screen and (min-width: 600px) {
      margin-top: 155px !important;
    }
  }
  &.multi-dynamic-subinfo-search {
    margin-top: 95px;
    @media screen and (min-width: 600px) {
      margin-top: 155px !important;
    }
  }
  //new changes 5/6/2021 by syafiq ali

  &.with-subheader-searchbar {
    margin-top: 80px;
    @media screen and (min-width: 600px) {
      margin-top: 145px !important;
    }
  }
  &.onlyContentScrollable {
    // padding-bottom: 60px;
    @media screen and (min-width: 600px) {
      // padding-bottom: 25px;
      overflow: auto;
      width: 100%;
      // max-height: 600px;
      position: relative;
      right: 5px; // new changes 10/6/2021 by syafiq ali
    }
  }

  &.with-date-range {
    margin-top: 45px;
    @media screen and (min-width: 600px) {
      margin-top: 110px !important;
    }
  }
}

.empty-listing {
  text-align: center;
  padding: 50px 12px;
  img {
    margin-bottom: 12px;
  }
  .smTitle {
    margin-bottom: 6px;
    white-space: unset;
  }
  .desc {
    white-space: unset;
  }
}

.remove-padding {
  padding: 0px !important;
  box-shadow: none !important;
}

.MuiButtonBase-root.Mui-disabled {
  background: #e8e8e8;
}

.menu-list {
  .MuiMenuItem-root {
    font-size: 14px;
  }
}

.fixed-search-header {
  position: fixed !important;
  width: 100%;
  z-index: 100;
  &.with-dropdown {
    top: 107px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 193px;
    }
  }
  &.single-info {
    top: 105px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 192px;
    }
  }
  &.with-dynamic {
    top: 81px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 81px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 121px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 212px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  @media screen and (min-width: 600px) {
    // width: calc(100% - 288px);
    width: calc(100% - 288px) !important;
    // position: fixed !important;
    // z-index: 100;
    top: 167px;
    transition: width 0.2s;
    right: 24px;
  }
}
.fixed-search-header-fw {
  position: fixed !important;
  width: 100%;
  z-index: 100;
  &.with-dropdown {
    top: 193px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 193px;
    }
  }
  &.single-info {
    top: 192px;
    @media screen and (min-width: 600px) {
      width: 100%;
      top: 192px;
    }
  }
  &.with-dynamic {
    top: 81px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 81px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 121px;
    @media screen and (min-width: 600px) {
      width: 100%;
      // position: fixed !important;
      // z-index: 100;
      top: 212px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  @media screen and (min-width: 600px) {
    // width: calc(100% - 288px);
    width: calc(100% - 48px) !important;
    // position: fixed !important;
    // z-index: 100;
    top: 167px;
    transition: width 0.2s;
    right: 24px;
  }
}

.full-width {
  left: 24px;
  width: calc(100% - 48px);
  //new changes 5/6/2021 by syafiq ali
  /* width */
  ::-webkit-scrollbar {
    width: 5.5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
