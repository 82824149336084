.align-maps {
  position: relative;
  text-align: -webkit-center;

  #bi-map-content {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;

    .dialog-box {
      position: absolute;
      width: 240px;
      height: auto;
      // display: flex;
      // flex-direction: column;
      padding: 10px;
      border: 1px solid black;
      background-color: white;
      border-style: none;
      border-radius: 10px;
      top: 1%;
      right: 1%;
      z-index: 5;
      box-sizing: border-box;
      font-size: 13px;

      .flex-container {
        display: flex;
      }

      .item-right {
        width: 100px;
        margin: 5px;
        flex-grow: 1;
        text-align: start;
        width: 100%;
      }

      .item-center {
        width: 100px;
        margin: 5px;
        flex-grow: 1;
        text-align: center;
      }

      .item-left {
        width: 100px;
        margin: 5px;
        flex-grow: 1;
        text-align: end;
        font-weight: 600;
        color: #ff9800;
      }
    }

    .dialog-box-bottom {
      position: absolute;
      width: auto;
      height: auto;
      // display: flex;
      // flex-direction: column;
      padding: 5px;
      border: 1px solid black;
      background-color: #ffffff9e;
      border-style: none;
      border-radius: 10px;
      bottom: 10%;
      left: 1%;
      z-index: 5;
      box-sizing: border-box;
      text-align: start;
      font-size: 13px;
    }
  }

  .bi-map-bottom-buttons {
    display: flex;
    justify-content: center;
    gap: 0.6rem;

    button {
      padding: 0.5rem;
      background: white;
      border: 2px solid darkgrey;
      border-radius: 0.25rem;
      font-size: inherit;
      font-weight: 600;
      pointer-events: auto;
      cursor: pointer;
      z-index: 2;

      &.active {
        border: 0px;
        background: #ff9800;
        pointer-events: none;
      }

      @media (max-width: 1920px) {
        padding: 0.25rem 0.5rem;
      }
    }

    @media (max-width: 1920px) {
      font-size: 0.75rem;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .dialog-content {
    color: black;
  }

  // .arrow {
  //   font-weight: 600;
  //   font-size: 600px;
  // }
}

.table-new {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
}

.table-header-right {
  text-align: right;
  font-weight: 500;
}

.table-header-left {
  text-align: left;
  padding-left: 15px;
  font-weight: 500;
}

.table-header-new {
  background-color: #e2e2e2;
  height: 35px;
}

.tabledata-right-orange {
  text-align: right;
  color: #ff9800;
  font-weight: 600;
}

.tabledata-right-green {
  text-align: right;
  color: #58cc92;
  font-weight: 600;
}

.tabledata-right-red {
  text-align: right;
  color: #ff294c;
  font-weight: 600;
}

.tabledata-right-blue {
  text-align: right;
  color: #0c28ff;
  font-weight: 600;
}

.table-data-left-small {
  text-align: left;
  font-weight: 300;
}

.table-data-left-small-orange {
  text-align: left;
  padding-left: 15px;
  font-weight: 360000;
  color: #ff9800;
}

.tabledata-right-small-grey {
  text-align: right;
  color: #969696;
  font-weight: 600;
}

.company {
  font-weight: 600;
  padding-left: 15px;
}

.arrow {
  top: 261px;
  left: 16px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.icon-size {
  top: 261px;
  left: 16px;
  width: 12px;
  height: 11px;
}

.icon-event {
  font-size: 1rem;
  color: black;
}

.icon-percentage {
  color: #ff9800;
  font-weight: 600;
}

.hr-black {
  border-top: none;
  border-left: none;
  border-right: none;
  border-block-color: black;
  border-width: 0.5px;
}

.hr-grey {
  border-top: none;
  border-left: none;
  border-right: none;
  border-block-color: #e2e2e2;
  border-width: 2px;
}

.hr-white {
  border-top: none;
  border-left: none;
  border-right: none;
  border-block-color: white;
  border-width: 2px;
}

.tr-new {
  border-top: none;
  border-left: none;
  border-right: none;
  border-block-color: #e2e2e2;
  border-width: 2px;
}

.grid-item {
  height: '100%';
  box-sizing: 'border-box';
  box-shadow: 'none';
}

.dialog-title {
  font-weight: 600;
  font-size: 10px;
  text-align: left;
}

.text-right-red {
  text-align: right;
  color: #ff294c;
  font-weight: 600;
  font-size: 10px;
  padding-left: 15px;
}
.text-right-blue {
  text-align: right;
  color: #0c28ff;
  font-weight: 600;
  font-size: 10px;
  padding-left: 15px;
}

.text-right-green {
  text-align: right;
  color: #58cc92;
  font-weight: 600;
  font-size: 10px;
  padding-left: 15px;
}

.text-right-orange {
  text-align: right;
  color: #ff9800;
  font-weight: 600;
  font-size: 10px;
  padding-left: 15px;
}

.text-right-orange-no-padding {
  text-align: right;
  color: #ff9800;
  font-weight: 600;
  font-size: 10px;
}

.text-right-grey {
  text-align: right;
  color: #969696;
  font-weight: 600;
  font-size: 10px;
}

.text-left-orange {
  text-align: left;
  color: #ff9800;
  font-weight: 600;
  font-size: 10px;
}
.text-left-black {
  color: #212121;
  text-align: left;
  font-weight: 600;
  font-size: 10px;
}

.text-left-orange-subcon {
  text-align: left;
  color: #ff9800;
  font-weight: 600;
  font-size: 10px;
  padding-left: 3px;
}

.text-left-grey {
  text-align: left;
  color: #969696;
  font-weight: 600;
  font-size: 10px;
}

.text-right-grey {
  text-align: right;
  color: #969696;
  font-weight: 600;
  font-size: 10px;
}

.text-header-right {
  text-align: right;
  font-weight: 600;
  font-size: 10px;
  align-self: center;
}

.text-header-left {
  text-align: left;
  font-weight: 600;
  font-size: 10px;
  align-self: center;
}

.text-header-container {
  background-color: #e2e2e2;
  height: 43px;
  margin-bottom: 8px;
}
.text-header-new {
  background-color: #e2e2e2;
  height: 35px;
  padding-left: 15px;
}

.icon {
  color: #212121;
  font-weight: 600;
  top: 261px;
  left: 16px;
  width: 12px;
  height: 11px;
}

.list-header-left {
  text-align: left;
  font-weight: 600;
  align-self: center;
  height: 50px;
  font-size: 14px;
}

.list-header-right {
  text-align: right;
  font-weight: 600;
  align-self: center;
  height: 50px;
  font-size: 14px;
}

.list-left {
  text-align: left;
  font-weight: 600;
  padding-left: 15px;
  height: 50px;
  font-size: 14px;
}

.submenu-text-right-orange {
  text-align: right;
  color: #ff9800;
  font-weight: 600;
  padding-left: 15px;
  height: 50px;
  font-size: 14px;
}

.submenu-text-right-orange-underline {
  text-align: right;
  color: #ff9800;
  font-weight: 600;
  padding-left: 15px;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.submenu-text-right-green {
  text-align: right;
  color: #58cc92;
  font-weight: 600;
  padding-left: 15px;
  height: 50px;
  font-size: 14px;
}

.submenu-text-below {
  background-color: #ffe4be;
  height: 50px;
  padding-left: 15px;
  font-size: 14px;
  padding-top: 8px;
}

.recharts-text.recharts-label {
  text-anchor: middle;
}

.infowindow-content {
  margin: 5px 0 0 0;
  font-weight: bold;
  text-align: center;
}

.infowindow-content-site-gallery {
  position: absolute;
  border-radius: 25px;
  width: 26px;
  height: 21px;
  color: white;
  background: #ff9800;
  padding-top: 6px;
  font-size: 12px;
  font-weight: bold;
  right: 58px;
  bottom: 18px;
}

.infoWindowArrow {
  background-color: rgb(255, 255, 255);
  border-left: 1px solid #cbd5e0;
  border-top: 1px solid #cbd5e0;
  height: 0.75rem;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -50%) rotate(225deg);
  width: 0.75rem;
}

.subInfoWindowArrow {
  background-color: rgb(255, 255, 255);
  border-left: 1px solid #cbd5e0;
  border-top: 1px solid #cbd5e0;
  height: 0.75rem;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.75rem;
}

.custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
