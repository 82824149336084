.hidden-content {
  .card-form {
    box-shadow: none;
  }
}

.hidden-table {
  .card-form {
    padding: 0;
    box-shadow: none;
  }
  .MuiCardContent-root {
    padding: 0;
  }
}

.cover-layer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: -10;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.costFooter {
  width: 100%;
  position: fixed;
  z-index: -100;
  bottom: 52px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 599px) {
    position: absolute;
    bottom: 52px;
    right: 0;
    margin-top: 20px;
  }

  .footer-label {
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 24px;
    display: flex;
    @media screen and (min-width: 600px) {
      width: calc(100% - 48px);
    }
  }
}

.sharedFooter {
  width: 100%;
  position: absolute;
  z-index: -40;
  bottom: 1px; // Changed back to 0 from 25px to remove CopyrightFooter
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 599px) {
    position: absolute;
    bottom: 0;
    z-index: -40;
    right: 0;
  }

  .footer-label {
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 24px;
    display: flex;
    @media screen and (min-width: 600px) {
      width: calc(100% - 80px);
    }

    .label-value {
      flex: 1;
      text-align: right;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.tooltip .tooltiptext {
  position: absolute;
  visibility: hidden;
  width: 150px;
  background-color: #d1d1d1;
  color: #555555;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  // position: absolute;
  z-index: 1;
  top: 85%;
  left: 85px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #d1d1d1 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext2 {
  position: absolute;
  visibility: hidden;
  width: 150px;
  background-color: #d1d1d1;
  color: #555555;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  // position: absolute;
  z-index: 1;
  top: 85%;
  left: 195px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext2::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #d1d1d1 transparent transparent transparent;
}

.tooltip:hover .tooltiptext2 {
  visibility: visible;
  opacity: 1;
}
