$primary-color: #ff9800;
$secondary-color: #faf2e8;
$color-light-blue: #e5edff;
$color-sky-blue: #78a9ff;
$color-dark-blue: #0019d8;
$color-blue: #2f53e6;
$color-green: #00bf0f;
$color-light-red: #ff4568;
$color-red: #ff0000;
$color-orange: #ffb11f;
$color-light-grey: #d3d3d3;
$color-grey: #b8b8b8;
$color-dark-grey: #808080;
$color-black: #212121;

.color-grey {
  color: $color-grey;
}

.color-light-grey {
  color: $color-light-grey;
}

.color-orange {
  color: $color-orange;
}

.color-red {
  color: $color-red;
}

.color-green {
  color: $color-green;
}

.color-blue {
  color: $color-blue;
}

.color-black {
  color: $color-black;
}

.color-primary-orange {
  color: $primary-color;
}

.color-secondary-orange {
  color: $secondary-color;
}

button.MuiButton-root.color-nav-alert {
  color: $color-light-blue;
}
