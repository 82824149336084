@import '../layout/color.scss';

.preview-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //   background: red;
}
.img-preview {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  padding: 4px;
  box-sizing: border-box;
}

.img-preview-alternate {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 300px;
  height: 300px;
  padding: 4px;
  box-sizing: border-box;
}

.vid_preview {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.close-btn.MuiAvatar-colorDefault {
  float: right;
  // margin-right: -18px;
  // margin-left: -6px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

.close-btn-alternate.MuiAvatar-colorDefault {
  float: right;
  margin-left: 284px;
  margin-right: -18px;
  margin-top: -6px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// .react-tel-input {
//   width: 50%;
//   font-size: 15px;
//   position: relative;
// }
// .react-tel-input .form-control {
//   width: 50%;
// }
// .phone-no-input {
//   font-size: 15px;
//   position: relative;
//   width: 50%;
// }
.react-tel-input {
  width: 50%;
  .form-control {
    padding: 14.5px 12px 14.5px 58px;
    width: 100%;
  }
}

.multipurpose {
  width: 100%;
  .multipurpose-content {
    // display: flex;
    width: 100%;

    .document-files {
      width: 80px;
      height: 80px;
    }
    .img-wrapper {
      .MuiListItemAvatar-root {
        min-width: 180px;
        flex-shrink: 0;
      }
    }
    .img-files {
      width: 200px;
      height: 150px;
      object-fit: cover;
    }
  }
  .multipurpose-fileupload {
    display: flex;
    width: 100%;
    padding: 12px 0px;
    justify-content: center;
    .avatar-wrapper {
      text-align: center;
    }
    .MuiAvatar-root {
      margin: 0px 12px;
      width: 50px;
      height: 50px;
      background: #e5edff;
      color: $primary-color;
      cursor: pointer;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
}

.recorder {
  //   outline-style: outset;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  background-color: #e5efff;
  border-radius: 20px;
}

.recorder-box {
  width: 80%;
  //   height: 100%;
  border-radius: 50px;
  margin-top: 18px;
}

.icon-style {
  color: #e4ecff;
  background-color: #bd0000;
  width: 40px;
  height: 40px;
  display: inline-block;
  align-items: center;
  padding: 3px;
  right: 10px;
  margin: 20%;
}

.icon-record {
  color: #e4ecff;
  background-color: #bd0000;
  width: 40px;
  height: 40px;
  display: inline-block;
  align-items: center;
  padding: 3px;
  right: 10px;
  margin: 20%;
}

.icon-direct {
  height: inherit;
  width: 70%;
  padding-left: 7px;
}

.avatar-span {
  padding-left: 25px;
}

.audio-style {
  display: inline-block;
  width: 100%;
  padding-top: 6px;
}

.audio-div {
  background-color: #eff3f4;
  border-radius: 20px;
}

.close-btn-audio.MuiAvatar-colorDefault {
  float: right;
  margin-left: -18px;
  margin-right: 1px;
  margin-top: -69px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

.close-btn-audio-new {
  float: right;
  margin-left: -18px;
  margin-right: 1px;
  margin-top: -69px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

