.table-wrap {
  .table-header {
    padding: 6px 12px;
    margin-bottom: 0px;
    background-color: #faf2e8;
    .MuiListItem-root .MuiListItemText-primary {
      display: unset;
    }
  }
  .table-content {
    padding: 6px 12px;
    width: 100%;
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }
  .table-content-v2 {
    padding: 6px 0px;
    width: 100%;
    .price-input-new {
      margin: 0;
    }
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }
  .table-listItem {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 6px 12px;
  }
  .num-width {
    width: 20px;
  }
  .md-width {
    width: 30px;
  }
  .right-width {
    width: 100px;
  }
  .md-right-width {
    width: 80px;
  }
  .md-space {
    width: 100px !important;
  }

  .right-space {
    width: 100px !important;
  }

  .empty-space {
    width: 40px;
  }

  .right-text {
    text-align: right;
  }
  .center-text {
    text-align: center;
  }
}

.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 1;
}

.stickyRow {
  position: sticky;
  top: 0;
  z-index: 2;
}

.custom-tree-v3 {
  margin-bottom: 10px;
  &.MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        &.MuiTableRow-head{     
          .MuiTableCell-root {
            padding: '0 !important';
          }
        }
      }
    }
  }
}

.custom-row-bgColor {
  &.MuiTableBody-root {
    .MuiTableRow-root:nth-child(odd){
      background-color: #faf2e8;
    }
    .MuiTableRow-root:nth-child(even){
      background-color:white;
    }
  }
}